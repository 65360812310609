import { Injectable } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as moment from 'moment';
import { StorageService } from './storage.service';
declare var google;
declare var EnjoyHint;
declare var $;



@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  _loading_title = 'Loading';
  timeZoneArr: any = [];
  availableFuncGlobal: any;
  _show_loader: boolean = false;
  public loader: string = "show";
  constructor(
    private notifications: NotificationsService,
    public globalStorage: StorageService,
  ) { }
  public settings: any;
  public modalConfig: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-lg'
  };
  config = {
    animated: true,
    keyboard: false,
    backdrop: true,
    ignoreBackdropClick: true
  };
  formatBytes(bytes, decimals) {
    if (bytes == 0) return '0 Bytes';
    var k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  ConvertdecimaltoTWo(a: any) {
    return a.toFixed(2);
  }
  showLoader(target: any) {
    let html = '<div class="card-disabled"><div class="card-portlets-loader"></div></div>';
    $(target).append(html);
  }
  getFeatPermissions(Feature) {

    var perms = [];
    perms = JSON.parse(localStorage.getItem("Roles"));
    perms = perms.filter(it => it.descp === Feature,)
    var roleArr = [];
    for (let i = 0; i < perms[0].functions.length; i++) {
      roleArr.push(perms[0].functions[i].descp);
    }
    return JSON.stringify(roleArr);
  }

  HideLoader(target: any) {
    $('.card-disabled').remove();
  }


  private tokenValue: string = '';
  showNotification(type: any, title: any, message: any) {
    this.notifications.create((title), message, NotificationType[type]);
  }
  convertSeconds(milliSeconds: any) {
    var utcSeconds = milliSeconds;
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds);
    return d;
  }
  // token getter
  get token(): string {
    let userdata = (this.globalStorage.getLocalStorage(this.settings.ETRAPP_USER));
    let user = userdata === null ? '' : userdata;
    if (user != null) {
      this.tokenValue = user;
    } else {
      this.tokenValue = '';
    }
    return this.tokenValue;
  }
  lgModal(className: any) {
    document.getElementsByClassName('modal-dialog')[0].classList.add(className);
    if (document.getElementsByClassName('modal-dialog')[1] !== undefined) {
      document.getElementsByClassName('modal-dialog')[1].classList.add(className);
    }
  }
  changeModalStyle(val: any) {
    if (document.getElementsByClassName('modal')[0] !== undefined && document.getElementsByClassName('enjoyhint').length > 0) {
      if (val && document.getElementsByClassName('modal')[0] !== undefined) {
        document.getElementsByClassName('modal')[0]['style'].zIndex = '100';
        document.getElementsByClassName('modal-backdrop')[0]['style'].zIndex = '5';
      } else {
        document.getElementsByClassName('modal')[0]['style'].zIndex = '';
        document.getElementsByClassName('modal-backdrop')[0]['style'].zIndex = '';
      }
    }
  }
  InitTour(config, key: any) {
    var enjoyhint_instance = new EnjoyHint({

      onStart: () => {
        //do something
        this.changeModalStyle(true);
      },
      onEnd: () => {
        this.changeModalStyle(false);
        document.getElementsByClassName('navbar')[0]['style'].zIndex = '';
        localStorage.setItem(key, 'true');
        document.getElementsByClassName('mainCanvas').length > 0 ?
          document.getElementsByClassName('mainCanvas')[0]['style'].height = '60vh' : ''
        //do something
      },
      onSkip: () => {
        this.changeModalStyle(false);
        document.getElementsByClassName('navbar')[0]['style'].zIndex = '';
        localStorage.setItem(key, 'true');

        document.getElementsByClassName('mainCanvas').length > 0 ?
          document.getElementsByClassName('mainCanvas')[0]['style'].height = '60vh' : ''
        //do something
      }
    });

    //set script config
    enjoyhint_instance.set(config);
    document.getElementsByClassName('navbar')[0]['style'].zIndex = -1;
    //run Enjoyhint script
    enjoyhint_instance.run();
  }
  tourIsEnabled(key) {
    if (localStorage.getItem(key) !== null) {
      let val = localStorage.getItem(key);
      if (val === 'false') {
        return true
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  formatTime(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return [
      h + ' h',
      m > 9 ? m + ' m' : (h ? '0' + m + ' m' : m + ' m' || '0'),
      s > 9 ? s + ' s' : '0' + s + ' s'
    ].filter(Boolean).join(':');
  }
  allowedFunction() {
    var availableFunc = localStorage.getItem('func');
    this.availableFuncGlobal = JSON.parse(atob(availableFunc));
    return this.availableFuncGlobal
  }
  getProObj() {
    var proStr = localStorage.getItem(this.settings.PRO_OBJ)
    if (proStr !== null || proStr !== undefined) {
      var obj = JSON.parse(atob(proStr))

      return obj
    }
  }

  getAdminObj() {
    var proStr = localStorage.getItem('USER_OBJ')
    if (proStr !== null || proStr !== undefined) {
      var obj = JSON.parse(atob(proStr))

      return obj
    }
  }

  drawMarker(pos, mMap, color, device, title?, stopOver = false, isDragable = false) {

    let link = ''
    if (device == 'cart' && color == 'red') {
      link = '../../../assets/img/redcat.svg';
    }
    else if (device == "etrapp") {
      if (color == 'red') {
        link = '../../../assets/img/redpinnew.svg'
      }
      else if (color == 'green') {
        link = 'b'
      }
      else if (color == 'yellow') {
        link = '../../../assets/img/yellowpinnew.svg'
      }
    }

    const icon = {
      url: link,
      scaledSize: new google.maps.Size(40, 40), // scaled size
    };

    if (color !== '') {
      return new google.maps.Marker({
        position: pos,
        map: mMap,
        scaledSize: new google.maps.Size(50, 50),
        icon: icon,
        label: {
          text: ` ${pos.count}`,

          className: 'marker-label',
        },
      });
    }
    else {
      return new google.maps.Marker({
        position: pos,
        map: mMap,
        label: {
          text: ` ${pos.count}`,
          className: 'marker-label',
        },
        scaledSize: new google.maps.Size(50, 50),
      });
    }

  }
  getDate(date: any) {
    let currentDate = new Date();
    date = new Date(date);
    let days = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) -
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) / (1000 * 60 * 60 * 24));
    let months = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) -
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) / (1000 * 60 * 60 * 24 * 30));
    let years = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) -
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) / (1000 * 60 * 60 * 24 * 30 * 12));
    let returnDate = "";
    var newMonth;
    if (years > 0) {
      newMonth = months - (years * 12);
      returnDate += years + `${years > 1 ? ' years' : ' year'}`;
    }
    if (months > 0) {
      days = days - (months * 30);
      newMonth == undefined ? returnDate += months + `${months > 1 ? ' months' : ' month'} ` : returnDate += newMonth + `${months > 1 ? ' months' : ' month'} `;
    }
    return returnDate += days + ` day${days > 1 ? 's' : ''}`;
  }
  localDateToUTC(dateISOLocalString: any) {
    let localLoginTime = moment.utc(dateISOLocalString).utc();
    var returnDate = moment(localLoginTime).format("DD-MMM-YYYY h:mm:ss A")
    return returnDate;
  }

  utcDateToLocal(dateISOUTCString: any) {
    let localLoginTime = moment.utc(dateISOUTCString).local();
    var returnDate = moment(localLoginTime).format("DD-MMM-YYYY h:mm:ss A")
    return returnDate;
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
  getTimeDifference(date: any, obj: any, dev: number) {
    let date1: Date = date;
    switch (obj) {
      case "4hours":
       if (dev == 3){
          return this.previousDate(date1, 4);
        }
        break;
      case "8hours":
        if (dev == 3) {
          return this.previousDate(date1, 8);
        }
        break;
      case "1day":
         if (dev == 3) { 
          return this.previousDate(date1, 24);
        }
        break;
        case "3day":
         if (dev == 3) { 
          return this.previousDate(date1, 72);
        }
        break;
      case "7days":
         if (dev == 3) {
          return this.previousDate(date1, 168);
        }
        break;
      case "30days":
       if (dev == 3) {
          return this.previousDate(date1, 720);
        }
        break;
      case "45days":
          if (dev == 3) {
             return this.previousDate(date1, 1080);
           }
           break;
      case "60days":
          if (dev == 3) {
              return this.previousDate(date1, 1440);
          }
             break;
    }
  }

  
  previousDate(date: any, hours: number) {
    ;
    date = new Date(date);
    let numberOfMiliseconds = date.getTime();
    let addMlSeconds = hours * 60 * 60 * 1000;
    let newDate = new Date(numberOfMiliseconds - addMlSeconds);
    return newDate;
  }
 
}
import { Injectable,  Injector } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpBackend,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/shared/services/global.service";
import { StorageService } from "src/app/shared/services/storage.service";
@Injectable({
  providedIn: "root",
})
export class Interceptor implements HttpInterceptor {
  private httpClient: HttpClient;
  constructor(
    handler: HttpBackend,
    public inj: Injector,
    public global: GlobalService,
    public globalStorage: StorageService,
    public route: Router
  ) {
    this.httpClient = new HttpClient(handler);
  }
  private applyCredentials = (req: HttpRequest<any>, token: string) => {
    if (token == "") {
      return req.clone({
        setHeaders: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
    } else {
      return req.clone({
        setHeaders: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + token,
        },
      });
    }
  };
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const global = this.inj.get(GlobalService);
    const authReq = this.applyCredentials(req, global.token);

    return next.handle(authReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event;
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 || error.status === 403) {
            localStorage.removeItem('ETRAPP');
            localStorage.removeItem('USER_OBJ');
            this.route.navigate(["/login"]);
            return throwError("Session expired. Please log in again.");
          }

          // For other errors, throw the error as usual
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class RequestServices {
  constructor(
    private http: HttpClient,
    private global: GlobalService
  ) { }

  public postRequest(url, body): Observable<any> {
    return this.http.post(this.global.settings.API_URL + url, body);
  }
 
  public getRequest(URL: any): Observable<any> {
    return this.http.get(this.global.settings.API_URL + URL);
  }

}
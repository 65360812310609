import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { ISidebar, SidebarService } from '../sidebar/sidebar.service';
import { GlobalService } from '../../services/global.service';
import { RequestServices } from 'src/app/shared/services/request.services';


import menuItems, { IMenuItem } from 'src/app/shared/constants/menu';
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  username = {
    name: ""
  };
  userObj = undefined;
  sidebar: ISidebar;
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  subscription: Subscription;
  fileUpdModalRef: BsModalRef;
  fileToUpload: any;
  modalRef: BsModalRef;
  checkroute = false;
  menuItems: IMenuItem[] = menuItems;
  checkdashboard = false;
  cTime: any = 'April 18, 2018 9:45am';
  public modalConfig: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-md',
  };
  proObj = undefined;
  licenseFile = undefined;
  fileMsg: string = '';
  isLicenseValid: boolean = true;
  fileText: string = "Select License File";
  _top_nav_bar = {
    licenseValidityStr: '',
    _btn_disable: false,
  }

  constructor(
    public global: GlobalService,
    private sidebarService: SidebarService,
    private modalService: BsModalService,
    public router: Router,
    private service: RequestServices,

  ) {
    this.isDarkModeActive = this.getColor().indexOf('dark') > -1 ? true : false;
  }

  ngOnInit(): void {
    this.userObj = JSON.parse(atob(localStorage.getItem(this.global.settings.PRO_OBJ)));
    if (this.router.url == "/collector") {
      this.checkroute = true;
    } else {
      this.checkroute = false;
    }
    if (this.router.url == "/dashboard") {
      this.checkdashboard = true;
    } else {
      this.checkdashboard = false;
    }

    this.subscription = this.sidebarService.getSidebar().subscribe(
      res => {
        this.sidebar = res;
      },
      err => {
        console.error(`An error occurred: ${err.message}`);
      }
    );

    this.proObj = this.global.getProObj();
  }
  fullScreenClick() {

    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  logoutuser() {
    var USERStr = localStorage.getItem(this.global.settings.PRO_OBJ)
    var obj = JSON.parse(atob(USERStr))
    var objid = this.proObj.loginId
    this.menuItems.map(it => {
      it.bool = false;
      if (it.subs !== undefined) {
        if (it.subs.length > 0) {
          it.subs.map(subObj => subObj.bool = false);
        }
      }
    })
    this.service.postRequest(`UserLogin/Logout?loginID=1`, objid).subscribe(
      (response) => {
       
        localStorage.removeItem('userCredentials');
        localStorage.removeItem(this.global.settings.ETRAPP_USER);
        localStorage.removeItem("UserName");
        localStorage.removeItem("ID");
        localStorage.removeItem("logo");
        localStorage.removeItem("empID");
        localStorage.removeItem("OrgID");
        localStorage.removeItem("RoleID");
        localStorage.removeItem("uType");
        this.openLogin();

      },
      (error) => {
        this.global.showNotification('Error', '', 'Something Went Wrong');
      }
    );
  }
  openLogin() {
    this.router.navigate(['/login']);
  }
  openOrgSettingModal(openOrgSettingTemp) {
    this.modalRef = this.modalService.show(openOrgSettingTemp, this.modalConfig);
  }
  closeOrgSettingModal() {
    this.modalRef.hide();
  }
  closeLicenseModal() {
    if (this.fileToUpload !== undefined) {
      this.fileToUpload = undefined;
      this.fileText = "Select License File";
    }
    this.modalRef.hide();
  }

  openUploadLicenseModal(template: TemplateRef<any>) {
    this.fileUpdModalRef = this.modalService.show(template, this.modalConfig);
  }
  mobileMenuButtonClick = (event: { stopPropagation: () => void; }, containerClassnames: string) => {
    if (event) { event.stopPropagation(); }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }
  routeToProfile() {
    this.router.navigate(['/profile']);
  }
  searchAreaClick(event) {
    event.stopPropagation();
  }
  changeText(event: any) {
    this.fileToUpload = event.target.files.item(0);
    this.fileText = (event.currentTarget.files[0].name.length > 22 ? event.currentTarget.files[0].name.substr(0, 20) + '...' : event.currentTarget.files[0].name);

  }

  openFileUploader() {
    this.fileMsg = '';
    document.getElementById('fileInput').click();
    var a = document.getElementById('fileInput');
  }
  menuButtonClick = (e: { stopPropagation: () => void; }, menuClickCount: number, containerClassnames: string) => {
    if (e) { e.stopPropagation(); }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );

  }



  getColor() {
    return localStorage.getItem(environment.themeColorStorageKey)
      ? localStorage.getItem(environment.themeColorStorageKey)
      : environment.defaultColor;
  }


  onDarkModeChange(event) {
    let color = this.getColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    localStorage.setItem(environment.themeColorStorageKey, color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }


}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './root-component/app.component';
import { SharedModule } from '../shared/modules/shared.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BootstrapModule } from '../components/bootstrap/bootstrap.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DocumentEditorModule } from '@txtextcontrol/tx-ng-document-editor';
import { Interceptor } from '../shared/interceptors';
import { GaugeChartModule } from 'angular-gauge-chart';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxIntlTelInputModule} from 'ngx-intl-tel-input';

@NgModule({
  imports: [
    BsDropdownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BootstrapModule,
    SimpleNotificationsModule.forRoot({
      position: ['top', 'right'],
      timeOut: 3000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: false,
      clickIconToClose: true,
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot(),
    HttpClientModule,
    SharedModule,
    DocumentEditorModule,
    GaugeChartModule,
    NgxDatatableModule,
    NgSelectModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    EditorModule,
    NgxIntlTelInputModule
  ],
  declarations: [
    AppComponent,
  ],
  providers: [ { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true, },],
  bootstrap: [AppComponent]
})
export class AppModule { }
<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="
        menuButtonClick(
          $event,
          sidebar.menuClickCount,
          sidebar.containerClassnames
        );
        (false)
      " href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg>
      {{ " " }}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>
    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>
    <div>
      <h3 class="text-primary ml-3 mb-0">
      </h3>
      <ol class="breadcrumb hider" style="font-size: 8px">
        <li class="breadcrumb-item ng-star-inserted">
          <a ng-reflect-router-link="/app">System status &nbsp;&nbsp;<span class="fa fa-wifi"
              style="color: green"></span></a>
        </li>
      </ol>
    </div>
  </div>
  <div class="text-center">
    <a class="navbar-logo" [routerLink]="['/noc']"><span class="logo d-none d-xs-block"></span><span
        class="logo-mobile d-block d-xs-none"></span>
    </a>
    <ol class="breadcrumb hider" style="font-size: 8px">
      <li class="breadcrumb-item ng-star-inserted">
        <a ng-reflect-router-link="/noc"><br /></a>
      </li>
    </ol>
  </div>
  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">
    </div>
    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0 mb-4" type="button" dropdownToggle>
        <span class="name" [title]="userObj.empName"> {{userObj.empName}}
        </span>
        <span>
          <img alt="Profile Picture" src="/assets/img/profile-pic.jpg" />
        </span>
      </button>
      <div class="dropdown-menu dropdown-menu-right mt-0" *dropdownMenu role="menu">
        <a class="dropdown-item c-pointer" (click)="logoutuser()">Sign out</a>
      </div>
    </div>
  </div>
</nav>
<ng-template #openOrgSetting>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Organization Settings</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeOrgSettingModal()">
      <span aria-hidden=" true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <perfect-scrollbar class="scroll noc-list-with-thumbs"
      [config]="{ suppressScrollX: true, wheelPropagation: false }">
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Theme Configuration</h5>
              <app-color-switcher></app-color-switcher>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-danger mb-1" (click)="closeOrgSettingModal()">
      Cancel
    </button>
  </div>
</ng-template>